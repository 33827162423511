import React, { useState } from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { mainWhite, innerWidth, screen } from "../components/common/variables";
import { getFirstNameWithGreet } from "../helpers";
import IconDoodle from "../images/svg/icon-doodle.svg";
import SEO from "../components/seo";
import SubBanner from "../components/banner/sub";
import Member from "../components/member";
import ModalMember from "../components/modal-member";
import FallbackImgSquare from "../components/fallback-images/fallback-person-member-img-square";

const Wrapper = styled.div`
  .banner {
    border-top: 22px ${mainWhite} solid;
    @media ${screen.xsmall} {
      border-top: 15px ${mainWhite} solid;
      border-bottom: 15px ${mainWhite} solid;
    }
    @media ${screen.large} {
      border-top: 17px ${mainWhite} solid;
      border-bottom: 17px ${mainWhite} solid;
    }

    .inner-wrapper {
      .featured-txt {
        color: ${mainWhite};
        text-align: center;
        @media ${screen.small} {
          text-align: left;
        }
        .inner-txt {
          .normal-p {
            margin: 10px 0 0 0;
          }
        }
      }
    }
    .bg {
      order: -1;
      @media ${screen.small} {
        order: 0;
      }
    }
  }

  .members {
    position: relative;

    .inner-wrapper {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 22px 0 50px 0;
      @media ${screen.xsmall} {
        padding: 100px 42px;
      }
      @media ${screen.small} {
        padding: 100px 30px;
      }
      @media ${screen.large} {
        padding: 110px 0;
      }

      .btn {
        text-align: center;
        @media ${screen.xsmall} {
          margin: 80px 0 0 0;
        }
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      @media ${screen.xsmall} {
        margin: 0 -5px;
      }
      @media ${screen.small} {
        margin: 0 -10px;
      }

      .each-member {
        width: 100%;
        margin: 0 0 52px 0;
        @media ${screen.xsmall} {
          width: calc(33.33% - 18px);
          margin: 9px 9px 38px 9px;
        }
        @media ${screen.small} {
          width: calc(25% - 18px);
        }
        @media ${screen.medium} {
          width: calc(25% - 18px);
          margin: 9px;
        }

        .overlay__btn {
          padding: 10px 18px;
        }
      }
    }

    .icon-decor {
      position: absolute;
      display: block;
      top: -49px;
      left: -35px;
      @media ${screen.small} {
        top: -88px;
        left: -30px;
      }
      svg {
        width: 75px;
        @media ${screen.small} {
          width: 127px;
        }
      }
    }
  }
`;
const MeetTheChoirPage = ({ data }) => {
  // current member selected state, will open the modal if not null
  const [selectedMember, setSelectedMember] = useState(null);

  const {
    banner_description,
    banner_heading,
    banner_image,
    meta_description,
    title_tag,
  } = data.content.data;

  // sorted by first name
  const choirMembers = data.choirMembers.edges.sort((a, b) => {
    if (a.node.data.name.text < b.node.data.name.text) return -1;
    return 1;
  });

  return (
    <Layout>
      <SEO title={title_tag} description={meta_description} />
      <Wrapper>
        <SubBanner className="banner" bgUrl={banner_image.url}>
          <div className="inner-wrapper">
            <div className="featured-txt">
              <div className="inner-txt">
                <h1 className="capital-heading">{banner_heading.text}</h1>
                <p className="normal-p">{banner_description}</p>
              </div>
            </div>
          </div>
        </SubBanner>

        <section className="members">
          <div className="inner-wrapper">
            <ul className="members__list">
              {choirMembers.map((item) => (
                <Member
                  className="each-member"
                  key={item.node.id}
                  firstName={getFirstNameWithGreet(item.node.data.name.text)}
                  onClick={() => {
                    setSelectedMember({
                      name: item.node.data.name.text,
                      title: item.node.data.position_role,
                      other_title: "",
                      description: item.node.data.description.html,
                      image:
                        item.node.data.photo.gatsbyImageData &&
                        item.node.data.photo.gatsbyImageData,
                    });
                  }}
                >
                  <div className="photo">
                    {item.node.data.photo.thumbnails.thumbnail
                      .gatsbyImageData ? (
                      <GatsbyImage
                        image={
                          item.node.data.photo.thumbnails.thumbnail
                            .gatsbyImageData
                        }
                        alt={item.node.data.photo.alt || "Portrait"}
                      />
                    ) : (
                      <FallbackImgSquare />
                    )}
                  </div>
                  <div className="overlay">
                    <h6 className="overlay__name">
                      {item.node.data.name.text}
                    </h6>
                    <button className="overlay__btn">
                      {getFirstNameWithGreet(item.node.data.name.text)}
                    </button>
                  </div>
                </Member>
              ))}
            </ul>
          </div>
          <span className="icon-decor">
            <IconDoodle />
          </span>
        </section>

        <ModalMember
          selectedMember={selectedMember}
          setSelectedMember={setSelectedMember}
        />
      </Wrapper>
    </Layout>
  );
};

export default MeetTheChoirPage;
export const dataQuery = graphql`
  {
    content: prismicMeetTheChoirPage {
      data {
        banner_heading {
          text
        }
        banner_description
        banner_image {
          url
        }
        title_tag
        meta_description
      }
    }
    choirMembers: allPrismicChoirMember {
      edges {
        node {
          id
          data {
            name {
              text
            }
            position_role
            description {
              html
            }
            photo {
              alt
              gatsbyImageData(placeholder: BLURRED)
              thumbnails {
                thumbnail {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    }
  }
`;
